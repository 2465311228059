<template>
    <div>
        <div :style="boxdimensions">
            <div class="image-placeholder w-auto h-full flex flex-col justify-center rounded overflow-hidden">
              <img v-if="image" :src="image" class="w-full block" :alt="alt ? alt : name">
              <div v-else class="w-full flex flex-col justify-center items-center gap-4">
                <feather-icon icon="ImageIcon" svgClasses="h-16 w-16 text-primary" />
                <p v-if="constraintsText" class="text-xs font-semibold">{{ constraintsText }}</p>
              </div>
            </div>
        </div>
        <div class="mt-3">
            <vs-button :disabled="disabled" type="border" class="button-select-image inline-block rounded-lg mr-2" @click="handlerChooseFiles()">{{ $t("image.select.image") }}</vs-button>
            <vs-button :disabled="disabled" v-if="image" icon="delete" class="inline-block rounded-lg mt-1" @click="handlerDelete()"></vs-button>
        </div>
        <vs-input
            type="file"
            :name="name"
            hidden
            :id="id"
            @change="onFileChange"
            :danger="validateError"
            :danger-text="validationMessage"
        />
    </div>
</template>

<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'

export default {
    name: 'image-form-form',
    props:{
        'id': String,
        'src': String,
        'name': String,
        'alt': String,
        'width': String,
        'height': String,
        'validSizes': {
          required: false,
          type: Array,
          default: () => []
        },
        'validationWidthEqualsHeight': {
            'type': Boolean,
            'default': false
        },
        'validationWeight': String,
        'validationMessage': String,

        'constraintsText': String,
        'disabled': {
          'type': Boolean,
          'default': false
        },
    },
    computed: {
        boxdimensions() {
            return `width:${this.width}px;height:${this.height}px`;
        },
        image() {
            return this.imageData ? this.imageData : this.src
        }
    },
    components: {
        LabelForm
    },
    data() {
        return {
            imageData: null,
            validateError:false
        }
    },
    created() {
        if (!this.id) {
            console.error("ImageForm id prop is required")
        }
    },
    watch: {
      'src': function() {
        this.imageData = null
      }
    },
    methods: {
        handlerChooseFiles() {
            document.getElementById(this.id).click()
        },
        onFileChange(e) {
            const file = e.target.files[0];
            const image = URL.createObjectURL(file);
            const img = new Image();
            const that = this;

            img.src = image;
            img.onload = (function() {
                let invalidSize = false;
                if (that.validSizes.length) {
                  invalidSize = !that.validSizes.some(({width, height}) => width == this.width && height == this.height)
                }

                const validationWeightError = that.validationWeight ? that.validationWeight < file.size / 1024 : false;

                let errorCondition = validationWeightError || invalidSize

                if(that.validationWidthEqualsHeight) {
                    const validationWidthEqualsHeightError = this.height !== this.width;
                    errorCondition = errorCondition || validationWidthEqualsHeightError
                }

                if ( errorCondition ) {
                    that.validateError = true
                } else {
                    that.imageData = image;
                    that.$emit('updateImage', file)
                    that.validateError = false;
                }
            })
        },
        handlerDelete() {
            this.imageData = null;
            this.$emit('updateImage', null)
            this.uploadReady = false
            this.$nextTick(() => {
                this.uploadReady = true
            })
        }
    }
}
</script>

<style scoped>
.button-select-image {
    height:38px;
}

.image-placeholder {
  background-color: #F4F4FA;
}
</style>
