var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { style: _vm.boxdimensions }, [
        _c(
          "div",
          {
            staticClass:
              "image-placeholder w-auto h-full flex flex-col justify-center rounded overflow-hidden"
          },
          [
            _vm.image
              ? _c("img", {
                  staticClass: "w-full block",
                  attrs: { src: _vm.image, alt: _vm.alt ? _vm.alt : _vm.name }
                })
              : _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-col justify-center items-center gap-4"
                  },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: "ImageIcon",
                        svgClasses: "h-16 w-16 text-primary"
                      }
                    }),
                    _vm.constraintsText
                      ? _c("p", { staticClass: "text-xs font-semibold" }, [
                          _vm._v(_vm._s(_vm.constraintsText))
                        ])
                      : _vm._e()
                  ],
                  1
                )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "button-select-image inline-block rounded-lg mr-2",
              attrs: { disabled: _vm.disabled, type: "border" },
              on: {
                click: function($event) {
                  return _vm.handlerChooseFiles()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("image.select.image")))]
          ),
          _vm.image
            ? _c("vs-button", {
                staticClass: "inline-block rounded-lg mt-1",
                attrs: { disabled: _vm.disabled, icon: "delete" },
                on: {
                  click: function($event) {
                    return _vm.handlerDelete()
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("vs-input", {
        attrs: {
          type: "file",
          name: _vm.name,
          hidden: "",
          id: _vm.id,
          danger: _vm.validateError,
          "danger-text": _vm.validationMessage
        },
        on: { change: _vm.onFileChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }